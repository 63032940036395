<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    request: Object,
    isPastor: Boolean,
    isLeader: Boolean,
});

const form = useForm({
    comments_pastor: props.request.comments_pastor,
    comments_leader: props.request.comments_leader,
});

const update = () => form.put(`/dashboard/departments/entry-requests/${props.request.id}`);
</script>

<template>
    <Dashboard page-title="Editar observação">
        <Breadcrumb
            current-page-name="Editar observação"
            :link-list="[
                {
                    previous_page_url: `departments/entry-requests/${request.id}`,
                    previous_page_name: 'Solicitação',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Editar observação</h4>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing || (!isLeader && !isPastor)"
            >
                <p
                    v-if="!isLeader && !isPastor"
                    class="bg-warning bg-opacity-10 border-start border-warning border-5 p-3 mt-3"
                >
                    Infelizmente, você não tem permissão para alterar as observações da solicitação.
                    Somente o pastor e o líder do departamento possuem essa autorização.
                </p>

                <div class="col-md-12" v-if="isLeader">
                    <label for="comments_leader" class="form-label"> Observações líder </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="comments_leader"
                        v-model="form.comments_leader"
                        type="text"
                        class="form-control"
                        name="comments_leader"
                        placeholder="Caso necessário, informe algo importante nesse ponto"
                        rows="5"
                    />

                    <small v-if="form.errors.comments_leader" class="text-danger mt-3">
                        {{ form.errors.comments_leader }}
                    </small>
                </div>
                <div class="col-md-12" v-if="isPastor">
                    <label for="comments_pastor" class="form-label"> Observações pastor </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="comments_pastor"
                        v-model="form.comments_pastor"
                        type="text"
                        class="form-control"
                        name="comments_pastor"
                        placeholder="Caso necessário, informe algo importante nesse ponto"
                        rows="5"
                    />

                    <small v-if="form.errors.comments_pastor" class="text-danger mt-3">
                        {{ form.errors.comments_pastor }}
                    </small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
